import React, {useState} from "react";
import "./CreateAccount.css";
import {validateField, emailExists, usernameExists} from './inputValidation';
import {doc, setDoc} from "firebase/firestore";
import {db, auth} from "./Firebase/firebase";
import {getAuth, createUserWithEmailAndPassword, sendEmailVerification} from "firebase/auth";


function CreateAccountPage() {
    const [inputFieldData, setInputFieldData] = useState({  // Holds the inputs
        firstName: '',
        lastName: '',
        username: '',
        password: '',
        email: '',
        role: 'Student'
    });

    const [inputFieldErrors, SetInputFieldErrors] = useState({}); // Store any field errors

    const createNewUser = async (email, password, firstName, lastName, username, role) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password); // Firebase authentication function to create account
            const user = userCredential.user; // Gets user object details

            // Store in DB
            await setDoc(doc(db, 'users', user.uid), {
                firstName: firstName,
                lastName: lastName,
                username: username,
                role: role,
                email: email,
                emailVerified: user.emailVerified // Add emailVerified status (initially false)
            });

            await sendEmailVerification(user); // firebase email verification
            alert("Account created successfully! A verification email has been sent. Please verify your email.");
        } catch (error) {
            console.error("Failed to create account: ", error);
            alert("Failed to create account.");
        }
    };

    const handleSubmit = async (event) => { // Function handles the submission to check if its valid
        event.preventDefault();
        let errors = {};
        let isFormValid = true;
        let isCreated = false;
    
        Object.keys(inputFieldData).forEach(key => { //Goes over inputs to check if filled
          if (!inputFieldData[key]) {
            errors[key] = '*This field is required';
            isFormValid = false;
          } 
          else { // Checks if validateField was good
            const fieldError = validateField(key, inputFieldData[key]);
            if (fieldError) {
              errors[key] = fieldError;
              isFormValid = false;
            } 
          }
        });
    
        // Check for duplicate email
        if (await emailExists(inputFieldData.email)) {
          errors.email = '*Email already in use';
          isFormValid = false;
        }
      
        // Check for duplicate username
        if (await usernameExists(inputFieldData.username)) {
          errors.username = '* Username already in use';
          isFormValid = false;
        }
    
        SetInputFieldErrors(errors);
        if (isFormValid) {
            console.log("Form is valid", inputFieldData);
            //write to database here
            //writeUserData(1, inputFieldData); //instead use createNewUser, uuidv4 handles unique ID
            await createNewUser(
                inputFieldData.email,
                inputFieldData.password,
                inputFieldData.firstName,
                inputFieldData.lastName,
                inputFieldData.username,
                inputFieldData.role
            ).catch(

            );
        }
    };
    
    const handleChange = (event) => { // Function updates the new/current inputs
        const { name, value } = event.target;
        setInputFieldData(prev => ({ ...prev, [name]: value }));
    };

    return (
        <div>
            <header>
                <a href="https://www.csus.edu/"><img url="../Images/Sacramento-State-University-logo_stacked.png"/></a>
            </header>
            <form id="CreateAccountForm" onSubmit={handleSubmit} style={{display: "block"}}>
                <div id="createAccountContents" style={{textAlign: "center"}}>
                    <div>
                        <label> <b>First Name:  </b>
                            <input
                                type="text"
                                name="firstName"
                                minLength={1}
                                maxLength={12}
                                value={inputFieldData.firstName}
                                onChange={handleChange}
                                placeholder="First Name"
                            />
                            <div>
                                {inputFieldErrors.firstName && <span className="error">{inputFieldErrors.firstName}</span>}
                            </div>
                        </label> 
                        <br />
                        <label> <b>Last Name:  </b>
                            <input
                                type="text"
                                name="lastName"
                                minLength={1}
                                maxLength={18}
                                value={inputFieldData.lastName}
                                onChange={handleChange}
                                placeholder="Last Name"
                            />
                            <div>
                                {inputFieldErrors.lastName && <span className="error">{inputFieldErrors.lastName}</span>}
                            </div>
                        </label>
                        <br />
                        <label> <b>Username:  </b>
                            <input
                                type="text"
                                name="username"
                                minLength={4}
                                maxLength={16}
                                value={inputFieldData.username}
                                onChange={handleChange}
                                placeholder="Username"
                            />
                            <div>
                                {inputFieldErrors.username && <span className="error">{inputFieldErrors.username}</span>}
                            </div>
                        </label>
                        <br />
                        <label> <b>Password:  </b>
                            <input
                                type="password"
                                name="password"
                                minLength={8}
                                maxLength={16}
                                value={inputFieldData.password}
                                onChange={handleChange}
                                placeholder="Password"
                                required
                            />
                            <div>
                                {inputFieldErrors.password && <span className="error">{inputFieldErrors.password}</span>}
                            </div>
                        </label>
                        <br />
                        <label> <b>Email:  </b>
                            <input
                                type="email"
                                name="email"
                                maxLength={40}
                                value={inputFieldData.email}
                                onChange={handleChange}
                                placeholder="email@csus.edu"
                            />
                            <div>
                                {inputFieldErrors.email && <span className="error">{inputFieldErrors.email}</span>}
                            </div>
                        </label>
                        <br />
                        <label
                            style={{ textAlign: "right", margin: "10px" }}
                            >Student
                            <input
                                type="radio"
                                name="role"
                                value="Student"
                                style={{ textAlign: "right"}}
                                checked={inputFieldData.role === 'Student'}
                                onChange={handleChange}
                            />
                        </label>
                        <label
                            style={{ textAlign: "right", margin: "10px" }}
                            >Professor
                            <input
                                type="radio"
                                name="role"
                                value="Professor"
                                checked={inputFieldData.role === 'Professor'}
                                onChange={handleChange}
                            />
                        </label>
                        <div></div>
                        <br />
                        <button 
                            onClick={handleSubmit}
                            type="submit">Create Account</button>
                        <br />
                    </div>
                </div>
                <a 
                    id="HaveAccount" 
                    href="LoginPortal.js" 
                    style={{ textAlign: "left", alignItems: "left", marginRight: 110}}> 
                    Already Have Account?
                </a>
                <a 
                    id="ForgotPassword" 
                    href="ForgotPassword.js" 
                    style={{ textAlign: "right", alignItems: "right", marginLeft: 110}}> 
                    Forgot Password?
                </a>
                
            </form>
        </div>
    );
}

export default CreateAccountPage;