import React, { useState, useContext, createContext, useEffect } from "react";
import './Webpages/CSS/TeamList.css';
import { collection, getDocs } from "firebase/firestore";
import { db } from "./Firebase/firebase";

const FilterContext = createContext();

export default function GroupList(){

    //TODO: add items from the database into the list
    //Also probably have to redo how the items are added, this probably doesnt work
    // const [items, setItems] = useState([]);
    // const addItem = (newItem) => {
    //     setItems([...items, newItem]);
    // };

    const [groups, setGroups] = useState([]);

    useEffect(() =>{
        const fetchGroups = async () => {
            try{
                const groupData = await getDocs(collection(db, "groups"));
                setGroups(groupData.docs.map((doc) => ({id: doc.id, ...doc.data()})));
            }
            catch(error){
                console.error("Error fetching groups", error);
            }
        };

        fetchGroups();
    })

    const [filters, setFilters] = useState({
        groupName: '',
        memberCount: '',
        hasAdvisor: '',
    });

    const handleFilterChange = (e) => {
        const{name, value} = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters, [name]: value,
        }));
    }


    //filter groups
    const filteredGroups = groups.filter((group) => {
        return(
            (group.name.toLowerCase().includes(filters.groupName.toLowerCase()))
            &&
            (filters.memberCount === '' || group.members.length.toString() === filters.memberCount)
            &&
            (filters.hasAdvisor === ''
                || (group.advisor !== "" && filters.hasAdvisor === "true")
                || (group.advisor === "" && filters.hasAdvisor === "false"))
        );
    });


    return(
        <FilterContext.Provider value={{
            filters, handleFilterChange
        }}>
            <div className="container">
                <h1 id="filterHeader">Filters</h1>
                <FilterControls/>
                <div className="filterTableDiv">
                    <table id="filterTable">
                        <thead>
                            <tr id="filterTableRow">
                                <th id="filterTableHeader">Group Name</th>
                                <th id="filterTableHeader">Member Count</th>
                                <th id="filterTableHeader">Has Advisor</th>
                            </tr>
                        </thead>
                        {filteredGroups.map((group) => {
                            return(
                                <tbody>
                                    <tr id="filterTableRow" key={group.id}>
                                        <td id="filterTableItem">{group.name}</td>
                                        <td id="filterTableItem">{group.members.length}</td>
                                        <td id="filterTableItem">{group.advisor === "" ? "No": "Yes"}</td>
                                    </tr>
                                </tbody>
                            )
                        })}
                    </table>
                </div>
            </div>
        </FilterContext.Provider>

    );

}

const FilterControls = () => {
    const {filters, handleFilterChange} = useContext(FilterContext);
    return(
        <div className="filters">
            <label id="filterLabel">
                <input
                    name="groupName"
                    type="text"
                    id="filterTextbox"
                    value={filters.groupName}
                    onChange={handleFilterChange}
                    placeholder="Search by Group Name"
                />
            </label>
            <label id="filterLabel">
                <select
                    name="memberCount"
                    id="filterDropdownMenu"
                    value={filters.memberCount}
                    onChange={handleFilterChange}
                >
                    <option value="">Any Member Amount</option>
                    <option value="1">1 Member</option>
                    <option value="2">2 Members</option>
                    <option value="3">3 Members</option>
                    <option value="4">4 Members</option>
                    <option value="5">5 Members</option>
                    <option value="6">6 Members</option>
                    <option value="7">7 Members</option>
                    <option value="8">8 Members</option>
                    <option value="9">9 Members</option>
                    <option value="10">10 Members</option>
                </select>
            </label>
            <label id="filterLabel">
                <select
                    name="hasAdvisor"
                    id="filterDropdownMenu"
                    value={filters.hasAdvisor}
                    onChange={handleFilterChange}
                >
                    <option value="">Any Advisor Status</option>
                    <option value="true">Has Advisor</option>
                    <option value="false">No Advisor</option>
                </select>
            </label>
        </div>
    )
}