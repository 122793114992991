import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserProvider, UserContext} from "./UserContext.js";
import ProtectedRoute from "./ProtectedRoute.js";

import Homepage from "./Webpages/homePage.js";
import LoginPortal from "./LoginPortal.js";
import CreateAccountPage from "./CreateAccount.js";
import reportWebVitals from "./reportWebVitals";
import UserPage from "./userPage.js";
import UserSettings from "./userSettings.js";
import UserClasses from "./userClasses.js";
import UserMessages from "./userMessages.js";
import UserProfile from "./userProfile.js";
import UserAdvisors from "./userAdvisors.js";
import ProfileLabAdvisorTab from "./ProfileLabAdvisorTab.js";
import Calendar from "./Calendar/Calendar.js";
import Sidebar from "./Sidebar.js";
import ProfessorCalendar from "./Calendar/professorCalendar.js";
import GroupList from "./GroupList.js";

import TestCalendar from "./Calendar/testCalendar.js";
import HomepageTest from "./Webpages/homepageTest.js";
import GroupManager from "./Calendar/GroupManager.js";
import ProfessorScheduling from "./Calendar/ProfessorScheduling.js";
import TimeSlotsPage from "./Calendar/TimeSlotPage.js";

import HomePageUser from "./Webpages/homePage.js";
import UpdateAccount from "./updateAccount.js"

const App = () => {
    const { currentUser, loading } = React.useContext(UserContext);

	if (loading) {
        return <div>Loading...</div>; // Show a loading indicator until the authentication state is known
    }

    return (
        <Router>
            {/* Show Sidebar only if the user is logged in */}
            {currentUser && <Sidebar />}
            <main>
                <Routes>
                    <Route path="/" element={<LoginPortal />} />
                    <Route path="/LoginPortal" element={<LoginPortal />} />
                    <Route path="/CreateAccount" element={<CreateAccountPage />} />
					<Route path="/homepagetest" element={<HomepageTest />} />
                    <Route path="/UserPage"element={ <UserPage />} />
					<Route path="/UserSettings"element={ <UserSettings />} />
					<Route path="/UserClasses"element={ <UserClasses />} />
					<Route path="/UserMessages"element={ <UserMessages />} />
					<Route path="/UserAdvisors"element={ <UserAdvisors />} />
					<Route path="/UserProfile"element={ <UserProfile />} />
					<Route path="/ProfileLabAdvisorTab"element={ <ProfileLabAdvisorTab />} />
					<Route path="/GroupList"element={<GroupList/>}/>
					<Route path="/Calendar"element={ <Calendar />} />
					<Route path="/GroupManager"element={ <GroupManager />} />
					<Route path="/ProfessorScheduling"element={ <ProfessorScheduling />} />
					<Route path="/timeslots/:professorId/:date"element={ <TimeSlotsPage />} />
					<Route path="/UpdateAccount"element={ <UpdateAccount/>} />
                </Routes>
            </main>
        </Router>
    );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <UserProvider>
            <App />
        </UserProvider>
    </React.StrictMode>
);

reportWebVitals();
