import React, { useState, useContext, useEffect } from "react";
import { addDoc, collection, getDocs, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../Firebase/firebase";
import { UserContext } from "../UserContext";
import DataTable from "react-data-table-component";
import "./ProfessorScheduling.css"; // Assuming you have a CSS file for styling

const ProfessorScheduling = () => {
    const { currentUser } = useContext(UserContext);
    const [day, setDay] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [duration, setDuration] = useState("");
    const [createdSlotGroups, setCreatedSlotGroups] = useState([]);

    // Ensure the professorId is the current user's UID if they are a professor
    const professorId = currentUser?.uid;

    useEffect(() => {
        // Fetch the created time slots for the professor
        const fetchCreatedSlots = async () => {
            if (professorId) {
                try {
                    const slotGroupsData = await getDocs(collection(db, "users", professorId, "timeSlotGroups"));
                    const slotGroups = slotGroupsData.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
                    setCreatedSlotGroups(slotGroups);
                } catch (error) {
                    console.error("Error fetching time slot groups:", error);
                }
            }
        };
        fetchCreatedSlots();
    }, [professorId]);

    const handleCreateSlots = async () => {
        if (!professorId) {
            console.error("Professor ID is required.");
            return;
        }

        if (!day || !startTime || !endTime || !duration) {
            alert("All fields are required.");
            return;
        }

        // Parse dates and times
        const start = new Date(`${day}T${startTime}`);
        const end = new Date(`${day}T${endTime}`);

        if (start >= end) {
            alert("End time must be after start time.");
            return;
        }

        let currentTime = new Date(start);
        const newSlots = []; // Contains individual slot objects

        // Logic to generate slots based on the given day, start time, end time, and duration
        while (currentTime < end) {
            let slotEndTime = new Date(currentTime.getTime() + duration * 60000); // Increment by duration
            if (slotEndTime <= end) {
                newSlots.push({
                    title: "Available Slot",
                    start: currentTime.toISOString(),
                    end: slotEndTime.toISOString(),
                    available: true,
                    professorId,
                });
            }
            currentTime = slotEndTime;
        }

        if (newSlots.length > 0) {
            // Save the slot group to Firestore as a single entry representing the entire group of slots
            try {
                const slotGroup = {
                    date: start.toDateString(),
                    startTime: new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }).format(start),
                    endTime: new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }).format(new Date(newSlots[newSlots.length - 1].end)),
                    duration: parseInt(duration),
                    slotsCount: newSlots.length, // Count of slots
                    slots: newSlots.map((slot) => ({
                        start: slot.start,
                        end: slot.end,
                        available: slot.available,
                    })), // Only save necessary slot details
                };

                await addDoc(collection(db, "users", professorId, "timeSlotGroups"), slotGroup);
                alert("Time slots created successfully!");

                // Update state to reflect the new group of slots
                setCreatedSlotGroups([...createdSlotGroups, slotGroup]);
            } catch (error) {
                console.error("Error creating time slot group:", error);
            }
        } else {
            alert("No time slots were created. Please check your inputs.");
        }
    };

    const handleDeleteSlotGroup = async (slotGroup) => {
        try {
            // Delete the entire slot group from Firestore
            await deleteDoc(doc(db, "users", professorId, "timeSlotGroups", slotGroup.id));
            setCreatedSlotGroups((prevGroups) => prevGroups.filter((g) => g.id !== slotGroup.id));
            alert("Time slot group deleted successfully!");
        } catch (error) {
            console.error("Error deleting time slot group:", error);
        }
    };

    const handleEditSlotGroup = async (slotGroup) => {
        const updatedTitle = prompt("Enter new title for the slot group:", slotGroup.title);
        if (updatedTitle) {
            try {
                await updateDoc(doc(db, "users", professorId, "timeSlotGroups", slotGroup.id), {
                    title: updatedTitle,
                });
                setCreatedSlotGroups((prevGroups) =>
                    prevGroups.map((g) => (g.id === slotGroup.id ? { ...g, title: updatedTitle } : g))
                );
                alert("Time slot group updated successfully!");
            } catch (error) {
                console.error("Error updating time slot group:", error);
            }
        }
    };

    const columns = [
        {
            name: "#",
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: "Available Date",
            selector: (row) => row.date,
            sortable: true,
        },
        {
            name: "Start Time",
            selector: (row) => row.startTime,
            sortable: true,
        },
        {
            name: "End Time",
            selector: (row) => row.endTime,
            sortable: true,
        },
        {
            name: "Duration (mins)",
            selector: (row) => row.duration,
            sortable: true,
        },
        {
            name: "Total Slots",
            selector: (row) => row.slotsCount,
            sortable: true,
        },
        {
            name: "Action",
            cell: (row) => (
                <div>
                    <button onClick={() => handleEditSlotGroup(row)} className="btn btn-secondary">Edit</button>
                    <button onClick={() => handleDeleteSlotGroup(row)} className="btn btn-danger">Delete</button>
                </div>
            ),
        },
    ];

    return (
        <div className="scheduling-container">
            <h2>Professor Scheduling</h2>
            <div className="form-container">
                <div className="form-group">
                    <label>Date:</label>
                    <input type="date" value={day} onChange={(e) => setDay(e.target.value)} className="form-control" />
                </div>
                <div className="form-group">
                    <label>Start Time:</label>
                    <input type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)} className="form-control" />
                </div>
                <div className="form-group">
                    <label>End Time:</label>
                    <input type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)} className="form-control" />
                </div>
                <div className="form-group">
                    <label>Slot Duration (minutes):</label>
                    <input
                        type="number"
                        value={duration}
                        placeholder="Duration (minutes)"
                        onChange={(e) => setDuration(e.target.value)}
                        className="form-control"
                    />
                </div>
                <button onClick={handleCreateSlots} className="btn btn-primary">Create Slots</button>
            </div>

            <div className="created-slots-container">
                <h3>Created Time Slots</h3>
                <DataTable
                    columns={columns}
                    data={createdSlotGroups}
                    pagination
                />
            </div>
        </div>
    );
};

export default ProfessorScheduling;
