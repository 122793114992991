import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../Firebase/firebase";
import { getDocs, addDoc, setDoc, collection, doc } from "firebase/firestore";
import { UserContext } from "../UserContext";
import { useContext } from "react";
import "./timeSlotsPage.css";

const TimeSlotsPage = () => {
    const { professorId, date } = useParams(); // Get professorId and date from the URL
    const [timeSlotGroup, setTimeSlotGroup] = useState(null);
    const { currentUser, role } = useContext(UserContext); // Get current user and role from UserContext
    const navigate = useNavigate();
    const [title, setTitle] = useState("");
    const [notes, setNotes] = useState("");
    const [selectedGroup, setSelectedGroup] = useState("");

    // Fetch the time slot group for the selected date
    useEffect(() => {
        const fetchTimeSlotGroup = async () => {
            try {
                console.log("Fetching time slot groups...");
                const timeSlotGroupsData = await getDocs(collection(db, "users", professorId, "timeSlotGroups"));
                const allGroups = timeSlotGroupsData.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                // Find the time slot group for the specified date
                const selectedGroup = allGroups.find(
                    (group) => new Date(group.date).toISOString().split("T")[0] === date
                );

                if (selectedGroup) {
                    // Sort the slots by start time for display purposes
                    selectedGroup.slots.sort((a, b) => new Date(a.start) - new Date(b.start));
                    setTimeSlotGroup(selectedGroup);
                    console.log("Time slot group found:", selectedGroup);
                } else {
                    setTimeSlotGroup(null);
                    console.log("No time slot group found for the selected date.");
                }
            } catch (error) {
                console.error("Error fetching time slot group:", error);
            }
        };
        fetchTimeSlotGroup();
    }, [professorId, date]);

    // Function to format the time to '01:00 PM' format
    const formatTime = (timeString) => {
        const options = { hour: '2-digit', minute: '2-digit', hour12: true };
        return new Date(timeString).toLocaleTimeString('en-US', options);
    };

    // Handle booking a time slot
    const handleBookSlot = async (slot) => {
        console.log("Booking slot clicked:", slot);

        if (role !== "Student") {
            alert("Only students can book time slots.");
            return;
        }

        if (!title) {
            console.log("Title is required but not provided.");
            alert("Please provide a title for the meeting.");
            return;
        }

        const newMeeting = {
            title,
            start: slot.start,
            end: slot.end,
            notes,
            attendees: [currentUser.uid, professorId],
            professorId,
            groupName: selectedGroup || "",
        };

        try {
            // Add meeting to student's meetings collection
            console.log("Adding meeting to student's meetings collection...");
            await addDoc(collection(db, "users", currentUser.uid, "meetings"), newMeeting);

            // Add meeting to professor's meetings collection
            console.log("Adding meeting to professor's meetings collection...");
            await addDoc(collection(db, "users", professorId, "meetings"), newMeeting);

            // Update the slot to indicate it's no longer available
            const updatedSlots = timeSlotGroup.slots.map((s) =>
                s.start === slot.start ? { ...s, available: false } : s
            );

            // Update the time slot group in Firestore
            console.log("Updating time slot group in Firestore...");
            await setDoc(doc(db, "users", professorId, "timeSlotGroups", timeSlotGroup.id), {
                ...timeSlotGroup,
                slots: updatedSlots,
            });

            // Update the state to reflect the change
            setTimeSlotGroup({ ...timeSlotGroup, slots: updatedSlots });

            alert("Time slot booked successfully!");
        } catch (error) {
            console.error("Error booking time slot:", error);
            alert("Failed to book time slot.");
        }
    };

    return (
        <div className="time-slots-container">
            <h2>Select Time Slot</h2>
            <div className="time-slots-list">
                {timeSlotGroup ? (
                    timeSlotGroup.slots.length > 0 ? (
                        timeSlotGroup.slots.map((slot) => (
                            <button
                                key={slot.start}
                                onClick={() => handleBookSlot(slot)}
                                className={`time-slot-button ${slot.available ? "available" : "booked"}`}
                                disabled={!slot.available}
                            >
                                {formatTime(slot.start)} - {formatTime(slot.end)}
                            </button>
                        ))
                    ) : (
                        <p>No available slots for this day.</p>
                    )
                ) : (
                    <p>Loading available slots...</p>
                )}
            </div>
            <div className="booking-details">
                <h3>Set Meeting Details</h3>
                <div className="form-group">
                    <label>Title:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Meeting Title"
                    />
                </div>
                <div className="form-group">
                    <label>Notes:</label>
                    <textarea
                        className="form-control"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        placeholder="e.g. Zoom link or any important notes"
                    />
                </div>
                <div className="form-group">
                    <label>Group (optional):</label>
                    <input
                        type="text"
                        className="form-control"
                        value={selectedGroup}
                        onChange={(e) => setSelectedGroup(e.target.value)}
                        placeholder="Group Name"
                    />
                </div>
            </div>
            <button onClick={() => navigate(-1)} className="btn btn-back">
                Back to Calendar
            </button>
        </div>
    );
};

export default TimeSlotsPage;
