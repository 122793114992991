import React, { useState, useRef, useEffect, useContext } from "react"; 
import FullCalendar from "@fullcalendar/react"; 
import dayGridPlugin from "@fullcalendar/daygrid"; 
import timeGridPlugin from "@fullcalendar/timegrid"; 
import interactionPlugin from "@fullcalendar/interaction"; 
import "./userCalendar.css"; 
import { db } from "../Firebase/firebase"; 

import { // Import Firestore functions for CRUD operations
    getDocs,
    addDoc,
    deleteDoc,
    collection,
    doc,
    setDoc,
    getDoc,
} from "firebase/firestore";
import { UserContext } from "../UserContext"; // Import UserContext for accessing current user and role
import { useNavigate } from "react-router-dom";

/**
 * RoleBasedCalendar component renders a calendar with role-based functionalities.
 * Professors can set their unavailability, and students can schedule meetings with professors.
 * 
 * @component
 * @example
 * return (
 *   <RoleBasedCalendar />
 * )
 * 
 * @returns {JSX.Element} The rendered component.
 */
export default function RoleBasedCalendar() {
    const calendarRef = useRef(null);
    const [modal, setModal] = useState(false); // State for controlling the modal visibility
    const [unavailability, setUnavailability] = useState([]);
    const [events, setEvents] = useState([]); // State for storing calendar events
    const [timeSlotGroups, setTimeSlotGroups] = useState([]); // State for storing professor's available time slot groups
    const [studentList, setStudentList] = useState([]);
    const [professorList, setProfessorList] = useState([]);
    const [professorId, setProfessorId] = useState("");
    const [title, setTitle] = useState("");
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [notes, setNotes] = useState("");
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedProfessor, setSelectedProfessor] = useState("");
    const [allUsers, setAllUsers] = useState([]);
    const { currentUser, role, loading } = useContext(UserContext);
    const [viewOwnCalendar, setViewOwnCalendar] = useState(true); // State for toggling between own calendar and professor's calendar
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState("");
    const [groups, setGroups] = useState("");
    const navigate = useNavigate();

    console.log("Current User in Calendar Component:", currentUser);
    console.log("Role in Calendar Component:", role);

    // Fetch users from Firestore and separate them into professors and students
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const usersData = await getDocs(collection(db, "users"));
                const usersList = usersData.docs.map((doc) => ({
                    uid: doc.id,
                    ...doc.data(),
                }));
                // Separate professors and students
                setProfessorList(usersList.filter((user) => user.role === "Professor"));
                setStudentList(usersList.filter((user) => user.role === "Student"));
                setAllUsers(usersList);
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };
        fetchUsers();
    }, []);

    // Fetch group from database
    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const groupsData = await getDocs(collection(db, "groups"));
                const groupsList = groupsData.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setGroups(groupsList);
            } catch (error) {
                console.error("Error fetching groups:", error);
            }
        };

        fetchGroups();
    }, []);

    // Fetch events, unavailability, and time slot groups based on the user's role and selected view
    useEffect(() => {
        if (!currentUser) {
            console.log("No current user, cannot fetch events.");
            return;
        }
    
        const getEvents = async () => {
            try {
                if (role === "Student") {
                    if (viewOwnCalendar) {
                        // Fetch the current user's meetings
                        console.log("Fetching events for student:", currentUser.uid);
                        const meetingsData = await getDocs(collection(db, "users", currentUser.uid, "meetings"));
                        const meetings = meetingsData.docs.map((doc) => ({
                            id: doc.id,
                            ...doc.data(),
                            color: "blue",
                            extendedProps: {
                                type: "meeting",
                            },
                        }));
    
                        setEvents(meetings);
                        setUnavailability([]);
                        setTimeSlotGroups([]);
                    } else if (professorId) {
                        // Fetch available time slot groups for the selected professor
                        console.log("Fetching time slot groups for professor:", professorId);
                        const slotGroupsData = await getDocs(collection(db, "users", professorId, "timeSlotGroups"));
                        const slotGroups = slotGroupsData.docs.map((doc) => ({
                            id: doc.id,
                            ...doc.data(),
                        }));
    
                        // Create events for each time slot group
                        const slotGroupEvents = slotGroups
                            .filter(group => group.date && group.startTime && group.endTime) // Filter out invalid groups
                            .map((group) => {
                                try {
                                    // Convert date and time into valid ISO strings
                                    const dateParts = new Date(group.date).toISOString().split('T')[0];
                                    const startTimeFormatted = convertTo24Hour(group.startTime);
                                    const endTimeFormatted = convertTo24Hour(group.endTime);
    
                                    if (!startTimeFormatted || !endTimeFormatted) {
                                        throw new Error(`Invalid time format for group: ${JSON.stringify(group)}`);
                                    }
    
                                    // Construct ISO formatted date strings
                                    const startDate = new Date(`${dateParts}T${startTimeFormatted}:00`);
                                    const endDate = new Date(`${dateParts}T${endTimeFormatted}:00`);
    
                                    if (isNaN(startDate) || isNaN(endDate)) {
                                        throw new Error(`Invalid date or time value for group: ${JSON.stringify(group)}`);
                                    }
    
                                    return {
                                        id: group.id,
                                        title: `Available Slots (${group.slotsCount})`,
                                        start: startDate.toISOString(),
                                        end: endDate.toISOString(),
                                        allDay: false,
                                        backgroundColor: "green",
                                        extendedProps: {
                                            type: "availableSlotsGroup",
                                            group,
                                        },
                                    };
                                } catch (error) {
                                    console.error("Error creating time slot event:", error);
                                    return null; // Skip this event
                                }
                            })
                            .filter(event => event !== null); // Filter out any null events
    
                        setTimeSlotGroups(slotGroupEvents);
                        setEvents([]);
                        setUnavailability([]);
                    }
                } else if (role === "Professor") {
                    // Fetch unavailability for the current professor
                    console.log("Fetching unavailability for professor:", currentUser.uid);
                    const unavailabilityData = await getDocs(collection(db, "users", currentUser.uid, "unavailability"));
                    const unavailability = unavailabilityData.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                        color: "grey",
                        extendedProps: {
                            type: "unavailability",
                        },
                    }));
    
                    // Fetch meetings for the professor if selected
                    console.log("Fetching meetings for professor:", currentUser.uid);
                    const meetingsData = await getDocs(collection(db, "users", currentUser.uid, "meetings"));
                    const meetings = meetingsData.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                        color: "blue",
                        extendedProps: {
                            type: "meeting",
                        },
                    }));
    
                    // Fetch available time slot groups for the professor
                    console.log("Fetching time slot groups for professor:", currentUser.uid);
                    const slotGroupsData = await getDocs(collection(db, "users", currentUser.uid, "timeSlotGroups"));
                    const slotGroups = slotGroupsData.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
    
                    const slotGroupEvents = slotGroups
                        .filter(group => group.date && group.startTime && group.endTime) // Filter out invalid groups
                        .map((group) => {
                            try {
                                // Convert date and time into valid ISO strings
                                const dateParts = new Date(group.date).toISOString().split('T')[0];
                                const startTimeFormatted = convertTo24Hour(group.startTime);
                                const endTimeFormatted = convertTo24Hour(group.endTime);
    
                                if (!startTimeFormatted || !endTimeFormatted) {
                                    throw new Error(`Invalid time format for group: ${JSON.stringify(group)}`);
                                }
    
                                // Construct ISO formatted date strings
                                const startDate = new Date(`${dateParts}T${startTimeFormatted}:00`);
                                const endDate = new Date(`${dateParts}T${endTimeFormatted}:00`);
    
                                if (isNaN(startDate) || isNaN(endDate)) {
                                    throw new Error(`Invalid date or time value for group: ${JSON.stringify(group)}`);
                                }
    
                                return {
                                    id: group.id,
                                    title: `Available Slots (${group.slotsCount})`,
                                    start: startDate.toISOString(),
                                    end: endDate.toISOString(),
                                    allDay: false,
                                    backgroundColor: "green",
                                    extendedProps: {
                                        type: "availableSlotsGroup",
                                        group,
                                    },
                                };
                            } catch (error) {
                                console.error("Error creating time slot event:", error);
                                return null; // Skip this event
                            }
                        })
                        .filter(event => event !== null); // Filter out any null events
    
                    // Set data to state
                    setUnavailability(unavailability);
                    setEvents([...meetings, ...slotGroupEvents]);
                }
            } catch (error) {
                console.error("Error fetching events:", error);
            }
        };
        getEvents();
    }, [currentUser, professorId, viewOwnCalendar, role, loading]);
    
    
    /**
     * Convert a 12-hour formatted time (e.g. 12:00 PM) to 24-hour formatted time (e.g. 12:00).
     * @param {string} timeStr - Time string in 12-hour format.
     * @returns {string} - Time string in 24-hour format.
     */
    const convertTo24Hour = (timeStr) => {
        if (!timeStr || typeof timeStr !== "string") {
            console.error("Invalid time string:", timeStr);
            return null; // Return null if time string is invalid
        }
    
        const [time, modifier] = timeStr.split(" ");
        if (!time || !modifier) {
            console.error("Invalid time format, expected time and modifier (e.g., '12:00 PM'):", timeStr);
            return null; // Return null if time or modifier is missing
        }
    
        let [hours, minutes, seconds] = time.split(":");
    
        if (hours === "12") {
            hours = modifier.toLowerCase() === "am" ? "00" : hours;
        } else {
            hours = modifier.toLowerCase() === "pm" ? String(parseInt(hours) + 12) : hours;
        }
    
        return `${hours}:${minutes}`;
    };
    
    

    // Handle click on "Available Slots" button
    const handleEventClick = (info) => {
        if (info.event.extendedProps.type === "availableSlotsGroup") {
            const group = info.event.extendedProps.group;
            const date = new Date(group.date).toISOString().split("T")[0];
            navigate(`/timeslots/${professorId}/${date}`);
        } else {
            setSelectedEvent(info.event);
            setModal(true);
        }
    };

    const handleDeleteEvent = async () => {
        if (!selectedEvent) return;

        if (
            window.confirm(`Are you sure you want to delete the event '${selectedEvent.title}'?`)
        ) {
            try {
                if (selectedEvent.extendedProps.type === "unavailability" && role === "Professor") {
                    await deleteDoc(doc(db, "users", currentUser.uid, "unavailability", selectedEvent.id));
                    setUnavailability(unavailability.filter((event) => event.id !== selectedEvent.id));
                } else if (selectedEvent.extendedProps.type === "meeting") {
                    // Only allow students to delete their own meetings or professors to delete their own meetings
                    if (role === "Student" || role === "Professor") {
                        await deleteDoc(doc(db, "users", currentUser.uid, "meetings", selectedEvent.id));
                        setEvents(events.filter((event) => event.id !== selectedEvent.id));
                    }
                }
                selectedEvent.remove();
                alert("Event deleted successfully!");
                setModal(false);
            } catch (error) {
                console.error("Error deleting event:", error);
                alert("Failed to delete the event.");
            }
        }
    };

    
    const handleUnavailabilitySubmit = async (e) => {
        e.preventDefault();
        if (role !== "Professor") {
            alert("Only professors can set unavailability.");
            return;
        }

        const newUnavailability = {
            title: "Unavailable Slot",
            start,
            end,
            allDay: false,
            color: "grey",
        };

        try {
            await addDoc(collection(db, "users", currentUser.uid, "unavailability"), newUnavailability);
            setUnavailability([...unavailability, newUnavailability]);
            alert("Unavailability saved to database!");
            setModal(false);
        } catch (error) {
            console.error("Error saving unavailability:", error);
            alert("Failed to save unavailability.");
        }
    };

    return (
        <div className="container">
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    {/* Calendar Header Section */}
                    <div className="calendar-header">
                        {role === "Student" && (
                            <div className="calendar-view-toggle">
                                <button
                                    className={`view-calendar-button ${viewOwnCalendar ? "active" : ""}`}
                                    onClick={() => setViewOwnCalendar(true)}
                                >
                                    View My Calendar
                                </button>
                                <button
                                    className={`view-calendar-button ${!viewOwnCalendar ? "active" : ""}`}
                                    onClick={() => setViewOwnCalendar(false)}
                                >
                                    View Professor Calendar
                                </button>
                            </div>
                        )}

                        {!viewOwnCalendar && role === "Student" && (
                            <div className="professor-select-container">
                                <label className="professor-select-label">Select a Professor:</label>
                                <select
                                    className="professor-select-dropdown"
                                    value={professorId}
                                    onChange={(e) => setProfessorId(e.target.value)}
                                >
                                    <option value="">Select a professor</option>
                                    {professorList
                                        .filter((user) => user.role === "Professor")
                                        .map((user) => (
                                            <option key={user.uid} value={user.uid}>
                                                {user.firstName} {user.lastName}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        )}
                    </div>

                    {/* Calendar Component */}
                    <div id="calendar">
                        <FullCalendar
                            ref={calendarRef}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            headerToolbar={{
                                start: "prev,next,today",
                                center: "title",
                                end: "dayGridMonth,timeGridWeek,timeGridDay",
                            }}
                            initialView="dayGridMonth"
                            events={[...unavailability, ...events, ...timeSlotGroups]}
                            eventClick={handleEventClick}
                            selectable={true}
                            allDaySlot={false}
                        />
                    </div>

                    {modal && (
                        <div className="custom-modal">
                            <div className="custom-modal-content">
                                <span className="close" onClick={() => setModal(false)}>
                                    &times;
                                </span>
                                {selectedEvent ? (
                                    <div className="event-details">
                                        <h2 className="event-title">Event Details</h2>
                                        <p className="event-text">
                                            <strong>Title:</strong> {selectedEvent.title}
                                        </p>
                                        <p className="event-text">
                                            <strong>Start:</strong> {new Date(selectedEvent.start).toLocaleString()}
                                        </p>
                                        <p className="event-text">
                                            <strong>End:</strong> {new Date(selectedEvent.end).toLocaleString()}
                                        </p>
                                        <p className="event-text">
                                            <strong>Notes:</strong> {selectedEvent.extendedProps.notes || "No notes provided"}
                                        </p>
                                        {selectedEvent.extendedProps.groupName && (
                                            <p className="event-text">
                                                <strong>Team:</strong> {selectedEvent.extendedProps.groupName}
                                            </p>
                                        )}
                                        {selectedEvent.extendedProps.type === "unavailability" && role === "Professor" && (
                                            <button className="delete-button" onClick={handleDeleteEvent}>
                                                Delete Unavailability
                                            </button>
                                        )}
                                        {selectedEvent.extendedProps.type === "meeting" &&
                                            (role === "Student" || role === "Professor") && (
                                                <button className="delete-button" onClick={handleDeleteEvent}>
                                                    Delete Meeting
                                                </button>
                                            )}
                                    </div>
                                ) : (
                                    role === "Professor" && (
                                        <>
                                            <h2>Set Unavailability</h2>
                                            <div className="form-group">
                                                <label>Start</label>
                                                <input
                                                    type="datetime-local"
                                                    className="form-control"
                                                    value={start}
                                                    onChange={(e) => setStart(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>End</label>
                                                <input
                                                    type="datetime-local"
                                                    className="form-control"
                                                    value={end}
                                                    onChange={(e) => setEnd(e.target.value)}
                                                />
                                            </div>
                                            <button className="btn" onClick={handleUnavailabilitySubmit}>
                                                Save Unavailability
                                            </button>
                                        </>
                                    )
                                )}
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}